<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>
        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <customer-links-comp></customer-links-comp>
                <div class="board_list">

                    <div class="notice_list">
                            <div class="n text-orange bg_title">
                                No
                            </div>
                            <div class="t text-orange bg_title">
                                공지사항
                            </div>
                    </div>
                    <div class="notice_list" v-for="(n,index) in noticeList" :key="index">
                        <div class="n">
                            {{index+1}}
                        </div>
                        <div class="t" @click="showContent(n.id)">
                            {{n.title}}
                        </div>
                        <div class="c" v-if="clickNumber === n.id" v-html="n.content">
                        </div>
                    </div>
                    <div>
                        <!--페이지-->
                        <pagination :page-index="pageNum"
                                    :total="total"
                                    :page-size="pageSize"
                                    @change="pageChange"
                                    v-if="noticeList.length > 0"></pagination>
                    </div>
                </div>
            </div>
        </div>

        <!-- right 메뉴 -->
        <right-bar-comp>
            <right-bar-banner-comp slot="rightBanner"></right-bar-banner-comp>
        </right-bar-comp>

        <foot-comp></foot-comp>

    </div>
</template>

<script>
    import {getNoticeList} from "../../network/userRequest";
    import TopbarComp from "../../components/TopbarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import sportsConst from "../../common/sportsConst";
    import RightBarComp from "../../components/RightBarComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import FootComp from "../../components/FootComp";
    import SubTitle from "../../components/SubTitle";
    import CustomerLinksComp from "../../components/links/CustomerLinksComp";
    import Pagination from "../../components/pagenation/Pagination";

    export default {
        name: "Notice",
        components: {
            Pagination,
            CustomerLinksComp,
            SubTitle,
            FootComp,
            RightBarBannerComp, SportsBetCartComp, RightBarComp, SportsLeftBarComp, LeftBarComp, TopbarComp
        },
        data() {
            return {
                sportsConst,
                noticeList: [],
                clickNumber: 0,
                pageNum: 1,
                pageSize: 15,
                total: 1,

            }
        },
        methods: {
            showContent(id){
                if(id === this.clickNumber){
                    this.clickNumber = 0
                }else {
                    this.clickNumber = id;
                }
            },
            getList(){
                /*공지사항추출*/
                getNoticeList(this.pageNum,this.pageSize).then(res => {
                    if (res.data.success) {
                        this.noticeList = res.data.data
                    }
                })
            },
            pageChange(page) {
                this.pageNum = page
                this.getList()
            },
        },
        created() {
            this.getList();
        }
    }
</script>

<style scoped>

</style>